import { useEffect, useState } from 'react';
import createApolloClient from '../../../api';
import getAllCategories from '../../../api/queries/posts/getAllCategories';
import getAllPosts from '../../../api/queries/posts/getAllPosts';
import sanitizeHTML from '../../../utils/sanitiseHTML';
import { convertDateToHuman } from '../../../utils/common/functions';

const client = createApolloClient();

const BlogListingHome = ({ section }) => {
  const [allCategory, setAllCategory] = useState([]);
  const [allBlog, setAllBlog] = useState([]);
  const [activeCategory, setActiveCategory] = useState(0);
  const [filteredBlog, setFilteredAllBlog] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const { data } = await client.query(getAllCategories());
      if (data?.categories?.nodes) {
        setAllCategory(data?.categories?.nodes);
      }
      const { data: blogData } = await client.query(getAllPosts());
      if (blogData?.posts?.nodes) {
        setAllBlog(blogData?.posts?.nodes);
        setFilteredAllBlog(blogData?.posts?.nodes);
      }
    };
    fetchData();
  }, []);
  return (
    <section className="section__blog">
      <div className="section__blog__list">
        <div className="section__blog__list__container">
          <div className="section__blog__item__list">
            {filteredBlog.slice(0, 3).map((data, index) => {
              return (
                <a key={index} href={`/blog/${data.slug}`} className="section__blog__item">
                  {data?.featuredImage?.node?.sourceUrl && (
                    <div className="section__blog__item__img-wrap">
                      <img
                        src={data?.featuredImage?.node?.sourceUrl}
                        width={400}
                        height={210}
                        alt=""
                      />
                    </div>
                  )}
                  <div className="section__blog__item__content-wrap">
                    {data?.title && (
                      // <Link href={`/blog/${data?.slug}`}>
                      <h6 className="section__blog__item__title">{data?.title}</h6>
                      // </Link>
                    )}
                    <label className="section__blog__item__date blog__date">
                      {data?.author?.node?.name && `By ${data?.author?.node?.name}`}
                      {data?.date && ` / ${convertDateToHuman(data?.date)}`}
                    </label>
                    {data?.excerpt && (
                      <p
                        className="section__blog__item__desc blog__desc"
                        dangerouslySetInnerHTML={{ __html: sanitizeHTML(data?.excerpt) }}
                      />
                    )}
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogListingHome;
