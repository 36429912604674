const Popup = ({ onClose }) => {
  return (
    // <div className="popup-overlay">
    //   <div className="popup">
    //     <div className="popup__close-button" onClick={onClose}>
    //       <img src="/images/popup-x.png" width="18" height="18" />
    //     </div>
    //     <img
    //       className="popup__image"
    //       src="/images/mobile-in-hand-img-.png"
    //       width="300px"
    //       height="400px"
    //     />
    //     <h4 className="popup__info">Become your own employment lawyer</h4>
    //     <h6 className="popup__text">
    //       To try our award-winning Employment Law Ai, create legal letters, translate your case into
    //       legal language and more!
    //     </h6>
    //     <a
    //       className="popup__button button"
    //       href="https://monacosolicitors.grapple.uk/?utm_source=ms-website&utm_medium=all-pages&utm_campaign=pop-up"
    //       target="_blank"
    //     >
    //       Try Now
    //     </a>
    //   </div>
    // </div>
    // <div className="popup-overlay">
    //   <div className="popup">
    //     <div className="popup__close-button" onClick={onClose}>
    //       <img src="/images/popup-x.png" width="10px" height="10px" />
    //     </div>
    //     <img
    //       className="popup__image"
    //       src="/images/popup-background.jpg"
    //       width="300px"
    //       height="400px"
    //     />
    //     <p className="popup__info">Try our award-winning Employment Law Ai</p>
    //     <a
    //       className="popup__button button"
    //       href="https://monacosolicitors.grapple.uk/?utm_source=ms-website&utm_medium=guide-pages&utm_campaign=pop-up"
    //       target="_blank"
    //     >
    //       Try Now
    //     </a>
    //   </div>
    // </div>

    <div className="popup-overlay-new">
      <div className="popup">
        <div className="popup__close-button" onClick={onClose}>
          <img src="/images/popup-x.png" width="18" height="18" />
        </div>
        <img className="popup__image" src="/images/popup-logo.png" width="103px" height="45px" />
        <h4 className="popup__info">Do I Have a Case?</h4>
        <h6 className="popup__text">Free Instant Assessment</h6>
        <a className="popup__button button" href="/do-i-have-a-case">
          Get Started
        </a>
      </div>
    </div>
  );
};

export default Popup;
