import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import getMenu from '../api/queries/menus/getMenu';
import createApolloClient from '../api';
import PageLayout from '../components/PageLayout';
import TeamSlider from '../components/TeamSlider';
import Reviews from '../components/Reviews';
import Representation from '../components/Representation';
import getHomePage from '../api/queries/homePage/getHomePage';
import sanitizeHTML from '../utils/sanitiseHTML';
import feesNotes from '../utils/data/feesNotes';
import Prices from '../components/Prices';
import Popup from '../components/Popup';
import BlogListingHome from '../components/Sections/BlogListingHome';

const client = createApolloClient();

const Homepage = ({ desktopHeaderData, aboutMenuData, toolsMenuData, homepage }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [cookie, setCookie] = useCookies(['internalUser']);
  const router = useRouter();
  const isInternalUser = router.query.internaluser === 'true';
  const {
    bannerSection,
    reviewSection,
    priceSection,
    whySection,
    guidesListingSection,
    virtualLawyerSection,
    clientSection,
    lawyerSection,
    awardSection,
  } = homepage;

  if (isInternalUser) {
    setCookie('internalUser', 'true', {
      path: '/',
    });
  }
  const indexStatus = {
    indexPage: true,
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <PageLayout
        menuData={desktopHeaderData}
        aboutMenuData={aboutMenuData}
        toolsMenuData={toolsMenuData}
        title="Homepage"
        indexStatus={indexStatus}
      >
        <div className="homepage">
          <section className="homepage__lawyers-banner">
            <div
              className="homepage__lawyers-banner__container desktop"
              data-bg={bannerSection?.desktopImage?.sourceUrl}
            >
              <h1 className="homepage__lawyers-banner__container__title">{bannerSection?.title}</h1>
              <p className="subtitle_1">{bannerSection?.paragraph}</p>
              <div className="homepage__lawyers-banner__cta">
                <a className="button" href={bannerSection?.buttonLink}>
                  {bannerSection?.buttonText}
                </a>
                <p>
                  1 hr Response Time <img src="/images/clock.svg" width="15" height="15" />
                </p>
                <a
                  href="https://www.monacosolicitors.co.uk/blog/giving-to-charity"
                  className="charity-badge"
                >
                  <img src="/images/charity.svg" width="156" height="156" />
                </a>
              </div>
            </div>
            <div
              className="homepage__lawyers-banner__container mobile"
              data-bg={bannerSection?.mobileImage?.sourceUrl}
            >
              <p className="homepage__lawyers-banner__container__title">{bannerSection?.title}</p>
              <p className="subtitle_1">{bannerSection?.paragraph}</p>
              <div className="homepage__lawyers-banner__cta">
                <a className="button" href={bannerSection?.buttonLink}>
                  {bannerSection?.buttonText}
                </a>
                <p>
                  1 hr Response Time <img src="/images/clock.svg" width="15" height="15" />
                </p>
              </div>
            </div>
          </section>
          <section className="homepage__bbc">
            <p>As Featured on the BBC</p>
          </section>
          <section className="homepage__reviews">
            {/* <a href="#">
              <img src="/images/charity.svg" width="156" height="156" />
            </a> */}
            <Reviews>
              <h2 dangerouslySetInnerHTML={{ __html: sanitizeHTML(reviewSection?.title) }}></h2>
              <div
                className="subtitle_1"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(reviewSection?.subtitle) }}
              />
            </Reviews>
            <section className="homepage__awards">
              <a
                href="https://www.monacosolicitors.co.uk/blog/giving-to-charity"
                className="charity-badge"
              >
                <img src="/images/charity.svg" width="156" height="156" />
              </a>
              <h3>{awardSection?.title}</h3>
              <div className="homepage__awards__desktop">
                <div className="homepage__awards__desktop__left">
                  <img
                    src={awardSection?.leftImage?.sourceUrl}
                    alt="award-image-left"
                    width="259"
                    height="136"
                  />
                  <p className="subtitle_1">{awardSection?.leftImageDescription}</p>
                </div>
                <div className="homepage__awards__desktop__right">
                  <img
                    src={awardSection?.rightImage?.sourceUrl}
                    alt="award-image-right"
                    width="259"
                    height="98"
                  />
                  <p className="subtitle_1">{awardSection?.rightImageDescription}</p>
                </div>
              </div>
              <div className="homepage__awards__mobile">
                <div className="homepage__awards__mobile__left">
                  <img
                    src={awardSection?.rightImage?.sourceUrl}
                    alt="award-image-right"
                    width="218"
                    height="81"
                  />
                  <p className="subtitle_1">{awardSection?.rightImageDescription}</p>
                </div>
                <div className="homepage__awards__mobile__right">
                  <img
                    src={awardSection?.leftImage?.sourceUrl}
                    alt="award-image-left"
                    width="218"
                    height="114"
                  />
                  <p className="subtitle_1">{awardSection?.leftImageDescription}</p>
                </div>
              </div>
            </section>
            <div
              className="homepage__reviews__bottom_paragraph subtitle_2"
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(reviewSection?.bottomParagraph) }}
            />
          </section>

          <section className="homepage__fees">
            <Prices
              feesNotes={feesNotes}
              title={priceSection?.title}
              subTitle={priceSection?.subtitle}
            >
              <div
                className="homepage__fees__footer subtitle_2"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(priceSection?.bottomParagraph) }}
              />
            </Prices>
          </section>

          <section className="homepage__representation-process">
            <Representation />
          </section>

          <section className="homepage__why">
            <div className="homepage__why__container">
              <h2>{whySection?.title}</h2>
              <div
                className="subtitle_1"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(whySection?.body) }}
              />
            </div>
          </section>

          {/* <section className="homepage__tools">
            <div className="homepage__tools__container">
              <h2>{virtualLawyerSection?.title}</h2>
              <div
                className="subtitle_1"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(virtualLawyerSection?.subtitle) }}
              />
              <div
                className="homepage__tools__img"
                data-bg={virtualLawyerSection?.image?.sourceUrl}
              />
              <div className="homepage__tools__virtual-link">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(virtualLawyerSection?.linkDescription),
                  }}
                />
              </div>
              <div className="homepage__tools__vl">
                <div
                  className="homepage__tools__vl__text subtitle_2"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(virtualLawyerSection?.description),
                  }}
                />
              </div>
            </div>
          </section> */}

          <section className="homepage__tools-new">
            <img
              className="homepage__tools-new__image"
              src="/images/popup-logo.png"
              width="127px"
              height="56px"
            />
            <h4 className="homepage__tools-new__info">{virtualLawyerSection?.title}</h4>
            <h6
              className="homepage__tools-new__text"
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(virtualLawyerSection?.subtitle) }}
            />
            <a
              className="homepage__tools-new__button button"
              href="https://www.monacosolicitors.co.uk/do-i-have-a-case"
            >
              Get Started
            </a>
          </section>
          <section>
            <div className="homepage__articles">
              <div className="homepage__articles__header">
                <h3>Practical Guides</h3>
                <a className="homepage__articles__link" href="/guides">
                  See all
                  <img src="/images/arrow.png" width="auto" height="auto" />
                </a>
              </div>
              <div className="homapage__articles__list">
                {guidesListingSection?.map((item) => (
                  <a href={item?.link}>
                    <img src={item?.image?.sourceUrl} />
                    <p>{item?.title}</p>
                  </a>
                ))}
              </div>
            </div>
          </section>
          <section>
            <div className="homepage__articles blog">
              <div className="homepage__articles__header">
                <h3>Latest blogs</h3>
                <a className="homepage__articles__link" href="/blog">
                  See all
                  <img src="/images/arrow.png" width="auto" height="auto" />
                </a>
              </div>
              <BlogListingHome />
            </div>
          </section>
          <section className="homepage__clients">
            <div className="homepage__clients__container">
              <div className="homepage__clients__about-us">
                <h2>{clientSection?.title}</h2>
                <div
                  className="subtitle_1"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(clientSection?.subtitle) }}
                />
              </div>
              <div className="homepage__clients__image" data-bg={clientSection?.image?.sourceUrl} />

              <div className="homepage__clients__contact-us">
                <a href={clientSection?.buttonLink} className="button">
                  {clientSection?.buttonText}
                </a>
                <div
                  className="subtitle_1 homepage__clients__contact-us__text"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(clientSection?.bottomText) }}
                />
              </div>
            </div>
          </section>

          <section className="homepage__team">
            <div className="homepage__team__container">
              <h2>{lawyerSection.title}</h2>
              <p className="subtitle_1">{lawyerSection.subtitle}</p>
              <div className="homepage__team__slider">
                <TeamSlider />
              </div>
            </div>
          </section>
        </div>
      </PageLayout>
      {showPopup && <Popup onClose={() => setShowPopup(false)} />}
    </>
  );
};

export default Homepage;

export async function getStaticProps() {
  const desktopHeaderData = await client.query(getMenu('dGVybToyNQ=='));
  const aboutMenuData = await client.query(getMenu('dGVybTo1NQ=='));
  const toolsMenuData = await client.query(getMenu('dGVybTo1Ng=='));
  const { data } = await client.query(getHomePage());
  const { acfOptionsHomepage } = data;
  const { homepage } = acfOptionsHomepage;

  return { props: { desktopHeaderData, aboutMenuData, toolsMenuData, homepage }, revalidate: 10 };
}
